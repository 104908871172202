import styled from "styled-components";

export const CommunityHeroCarouselDiv = styled.div`
  --headerSize: 62px;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  padding: 20px 0 25px 0;
  position: relative;
  height: 100%;
  background-color: ${(props) => (props.leasing ? "var(--white)" : "none")};
  --headerColor: ${(props) => (props.leasing ? "var(--purple)" : "var(--red)")};
  & h5:first-of-type {
    margin-top: 15px;
    & + h5 {
      margin-bottom: 30px;
      color: var(--headerColor);
    }
  }
  & h2 {
    font-size: var(--headerSize);
  }
  & > div {
    display: flex;
    position: relative;
    flex-direction: column;
    width: 100%;
    align-items: center;
    height: 100%;
    height: 642px;
    padding-bottom: 25px;
  }
  @media (max-width: 1023px) {
    --headerSize: 48px;
    & > div {
      /* height: 500px; */
    }
  }
  @media (max-width: 820px) {
    --headerSize: 32px;
    & > div {
      height: 260px;
    }
  }
  @media (max-width: 767px) {
    --headerSize: 28px;
  }
`;

export const TextDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  padding: 0;
  max-width: 600px;
  top: 0;
  z-index: 2;
  position: absolute;
  & h2 {
    color: var(--headerColor);
    text-align: center;
  }
`;

export const CarouselDiv = styled.div`
  width: 100%;
  /* height: 632px; */
  top: 70px;
  position: absolute;
  z-index: 1;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    height: 280px;
    left: 0;
    right: 0;
    z-index: 10;
    pointer-events: none;
    margin-top: -20px;
    background: linear-gradient(
      to bottom,
      var(--white),
      rgba(255, 255, 255, 0)
    );
  }
`;
