import React from "react";
import PropTypes from "prop-types";
import MiscSocialSmall from "./../icons/MiscSocialSmall";
import CoreCarousel from "./../CoreCarousel";
import { H2BigStrong, H2Big, H5SmallMedium } from "./../Typography";
import { CommunityHeroCarouselDiv, CarouselDiv, TextDiv } from "./elements";
import { getCommunityTypeFromID } from "./../CommunityFilter";

// This is used inside of a community page
// TODO:

const CommunityHeroCarousel = ({
  communityMemberName,
  subtitle,
  communityMemberType,
  communityMemberImages,
}) => {
  // console.log(communityMemberType);
  // TODO: This is only getting the first community member type.
  const [currentSlide, setCurrentSlide] = React.useState(
    communityMemberImages && communityMemberImages.length > 1 ? 1 : 0
  );
  return (
    <CommunityHeroCarouselDiv>
      {/*<MiscSocialSmall color={"var(--red)"} />*/}
      <H5SmallMedium>Discover The Avenue</H5SmallMedium>
      <H5SmallMedium>
        {getCommunityTypeFromID(communityMemberType)}
      </H5SmallMedium>
      <div>
        <CarouselDiv>
          <CoreCarousel
            images={communityMemberImages}
            currentSlide={currentSlide}
            setCurrentSlide={setCurrentSlide}
            controlColor={"var(--red)"}
            shadow={false}
            imagesAreUrls
          />
        </CarouselDiv>
        <TextDiv>
          <H2BigStrong>{communityMemberName}</H2BigStrong>
          <H2Big>{subtitle}</H2Big>
        </TextDiv>
      </div>
    </CommunityHeroCarouselDiv>
  );
};

export default CommunityHeroCarousel;

CommunityHeroCarousel.propTypes = {
  communityMemberName: PropTypes.string,
  subtitle: PropTypes.string,
  communityMemberType: PropTypes.string,
  communityMemberImages: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.element,
      link: PropTypes.string,
    })
  ),
};

export const LeasingCarousel = ({ title, subtitle, communityMemberImages }) => {
  const [currentSlide, setCurrentSlide] = React.useState(
    communityMemberImages && communityMemberImages.length > 1 ? 1 : 0
  );
  return (
    <CommunityHeroCarouselDiv leasing>
      <MiscSocialSmall color={"var(--purple)"} />
      <H5SmallMedium>{subtitle}</H5SmallMedium>
      <H5SmallMedium> </H5SmallMedium>
      <div>
        <CarouselDiv>
          <CoreCarousel
            images={communityMemberImages}
            currentSlide={currentSlide}
            setCurrentSlide={setCurrentSlide}
            controlColor={"var(--purple)"}
            shadow={false}
            imagesAreUrls
          />
        </CarouselDiv>
        <TextDiv>
          <H2BigStrong>{title}</H2BigStrong>
        </TextDiv>
      </div>
    </CommunityHeroCarouselDiv>
  );
};
