import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Layout from "./../components/Layout";
import { LeasingCarousel } from "./../components/CommunityHeroCarousel";
import ArticleBody from "./../components/ArticleBody";
import { SmallButton, BigButton } from "./../components/Buttons";
import Download from "./../components/icons/Download";
import RegisterYourInterestForm from "./../components/RegisterYourInterestForm";

const LeasingVenueHirePage = () => {
  const leasingQuery = useStaticQuery(graphql`
    {
      craftLeasingVenueHireLeasingVenueHireEntry {
        title
        subtitle
        callToAction
        venuePdfForDownload {
          url
        }
        editorialImageSlideshowImages {
          ... on Craft_editorialAssets_Asset {
            slide: localFile {
              publicURL
              childImageSharp {
                fixed(width: 820, height: 600, fit: COVER) {
                  src
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
        richContent {
          ... on Craft_richContent_matterportEmbed_BlockType {
            typeHandle
            matterportUrl
          }
          ... on Craft_richContent_podiantPlayer_BlockType {
            podiantUrl
            typeHandle
          }
          ... on Craft_richContent_anghamiPlayer_BlockType {
            anghamiUrl
            typeHandle
          }
          ... on Craft_richContent_soundcloudPlayer_BlockType {
            soundcloudUrl
            typeHandle
          }
          ... on Craft_richContent_spotifyPlayer_BlockType {
            spotifyUrl
            typeHandle
          }
          ... on Craft_richContent_image_BlockType {
            id
            typeHandle
            imageWithCaptionImage {
              ... on Craft_communityAssets_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(width: 715, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              ... on Craft_eventsAssets_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(width: 715, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              ... on Craft_editorialAssets_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(width: 715, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
            imageWithCaptionCaption
          }
          ... on Craft_richContent_slideShow_BlockType {
            id
            typeHandle
            images {
              ... on Craft_communityAssets_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 460, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              ... on Craft_eventsAssets_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 460, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              ... on Craft_editorialAssets_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 460, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
            carouselCaption
          }
          ... on Craft_richContent_richText_BlockType {
            id
            typeHandle
            richText
          }
          ... on Craft_richContent_imageSlider_BlockType {
            typeHandle
            imageSliderImages {
              ... on Craft_communityAssets_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 460, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              ... on Craft_eventsAssets_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 460, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              ... on Craft_editorialAssets_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 460, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
            imageSliderCaptions {
              captionLine4
              captionLine3
              captionLine2
              captionLine1
            }
          }
          ... on Craft_richContent_videoWithCaption_BlockType {
            typeHandle
            videoWithCaptionCaption
            videoWithCaptionVideoOnTheLeft
            videoWithCaptionUseCredit
            videoWithCaptionUrl
            videoWithCaptionVideo {
              url
            }
          }
          ... on Craft_richContent_photoWithQuote_BlockType {
            typeHandle
            photoWithQuoteImage {
              ... on Craft_communityAssets_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 460, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              ... on Craft_eventsAssets_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 460, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              ... on Craft_editorialAssets_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 460, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
            photoWithQuoteImageOnTheLeft
            photoWithQuoteImageQuote
            photoWithQuoteImageQuoteAttribution
          }
        }
      }
    }
  `).craftLeasingVenueHireLeasingVenueHireEntry;
  const {
    title,
    richContent,
    subtitle,
    venuePdfForDownload,
    editorialImageSlideshowImages,
    callToAction,
  } = leasingQuery;
  const [formShown, setFormShown] = React.useState(false);
  return (
    <Layout
      backgroundColor={"var(--green)"}
      footerColor={"var(--green)"}
      title={title}
      SEODescription={subtitle}
    >
      <LeasingCarousel
        title={title}
        subtitle={"Events & Venue Hire"}
        communityMemberImages={
          editorialImageSlideshowImages.length
            ? editorialImageSlideshowImages.map((x) => x.slide.childImageSharp)
            : [null]
        }
      />
      <div style={{ backgroundColor: "var(--white)" }}>
        <ArticleBody
          subTitle={subtitle}
          subTitleColor={"var(--black)"}
          mainColumn={richContent}
          leftColumn={
            venuePdfForDownload.length && venuePdfForDownload[0].url ? (
              <SmallButton
                onClick={(e) => {
                  e.preventDefault();
                  console.log("Donwload this!", venuePdfForDownload[0].url);
                }}
              >
                <Download color={"var(--slate)"} />
                Download PDF pack
              </SmallButton>
            ) : null
          }
          color={"var(--purple)"}
          textColor={"var(--black)"}
        />
      </div>
      <div
        style={{
          width: "100%",
          backgroundColor: "var(--green)",
          margin: "50px auto",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <a>
          <BigButton
            color={"var(--purple)"}
            onClick={() => {
              setFormShown(true);
            }}
          >
            {callToAction || "Design your Experience"}
          </BigButton>
        </a>
      </div>
      {formShown ? (
        <RegisterYourInterestForm
          closeForm={() => {
            setFormShown(false);
          }}
        />
      ) : null}
    </Layout>
  );
};

export default LeasingVenueHirePage;
